



































































import { Component, Prop, mixins } from 'nuxt-property-decorator';

import { usePerformanceEvents } from '@/composables/performance-events';
import { formatSv } from '@/composables/datefns';

import { ComposableMixin } from '@/mixins/composable';
import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class BlockUpcomingShows extends mixins(
  ComposableMixin(usePerformanceEvents),
) {
  @Prop(String)
  backgroundColor!: string

  @Prop(String)
  title!: string

  modalOpen = false;

  created () {
    this.performanceEventsLoading = true;
  }

  get shows () {
    return this.performanceEvents
      .slice(0, 8)
      .map((event) => {
        return {
          title: event.performance.title,
          slug: event.performance.pageUrl,
          subtitle: `${formatSv(new Date(event.startDate), "d MMM 'kl.' HH:mm")} i ${event.locationStage}`,
          image: event.performance.image,
          cancelled: event.cancelled,
          soldOut: event.soldOut,
          bookingLink: event.purchaseUrl,
        };
      });
  }

  // shows = [
  //   {
  //     title: 'Amélie',
  //     slug: 'cool-dude',
  //     image: 'https://d1i5cf397ceebi.cloudfront.net/uploads/media/slider_image/image/2338/normal_c3d46294-baa7-4529-91f3-d3bd2dd9ec95.jpg',
  //     subtitle: '5 jun kl. 18:00 i Norrköping',
  //     cancelled: false,
  //     soldOut: false,
  //     bookingLink: 'tix.se',
  //   },
  //   {
  //     title: 'Peer Gynt',
  //     slug: 'dumar',
  //     image: 'https://d1i5cf397ceebi.cloudfront.net/uploads/media/slider_image/image/1979/normal_71dd5871-3b42-43aa-8aa1-ee4e0301df71.jpg',
  //     subtitle: '5 jun kl. 18:00 i Norrköping',
  //     cancelled: true,
  //     soldOut: true,
  //     bookingLink: 'tix.se',
  //   },
  //   {
  //     title: 'Expedition kyla',
  //     slug: 'den-svenska-sonen',
  //     image: 'https://d1i5cf397ceebi.cloudfront.net/uploads/media/slider_image/image/1978/normal_8f5066ef-cf60-4909-a326-f3c94208e2b8.jpg',
  //     subtitle: '5 jun kl. 18:00 i Norrköping',
  //     cancelled: false,
  //     soldOut: true,
  //     bookingLink: 'tix.se',
  //   },
  //   {
  //     title: 'Alla mina liv',
  //     slug: 'the-last-fish',
  //     image: 'https://d1i5cf397ceebi.cloudfront.net/uploads/media/slider_image/image/2336/normal_6340e046-1fc6-47d0-ad5b-5c0dfcc78a6d.jpg',
  //     subtitle: '5 jun kl. 18:00 i Norrköping',
  //     cancelled: false,
  //     soldOut: false,
  //   },
  //   {
  //     title: 'Eufori',
  //     slug: 'cool-dude',
  //     image: 'https://d1i5cf397ceebi.cloudfront.net/uploads/media/slider_image/image/2374/normal_04638663-e312-4d63-b638-a517ea63aa0e.jpg',
  //     subtitle: '5 jun kl. 18:00 i Norrköping',
  //     cancelled: false,
  //     soldOut: false,
  //     bookingLink: 'tix.se',
  //   },
  //   {
  //     title: 'Östgötateaterns arbetarorkester långtitel',
  //     slug: 'cool-dude',
  //     image: 'https://d1i5cf397ceebi.cloudfront.net/uploads/media/slider_image/image/2358/normal_f4a3da43-0993-4e75-8965-f6a050d376b7.jpg',
  //     subtitle: '5 jun kl. 18:00 i Norrköping',
  //     cancelled: false,
  //     soldOut: false,
  //     bookingLink: 'tix.se',
  //   },
  // ];

  openModal (e: boolean) {
    this.modalOpen = e;
  }
}
